import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';


const FaqPage = () => {
   return (
    <>
    <Helmet>
        <title>Atlantis | FAQs</title>
      </Helmet>
      <Header/>
      <section className="py-9">
      <div className="container">
        <div className="row">
            <div className="col-sm-12 text-sm-start text-center">
                        <h1 className="display-5 fw-bold mb-4">FAQs</h1>
                         <p className="fs-1 text-justify"><b>What is Atlantis?</b><br/>
                         Atlantis is a comprehensive database designed to help understand the functional role of specific protein residues. It integrates sequence-level functional annotations, protein structures, interaction networks, and information about genetic variants.
                        </p>
                        <p className="fs-1 text-justify"><b>What kind of data does Atlantis provide?</b><br/>
                         Atlantis provides detailed annotations for protein residues, including UniProt features, protein domains, post-translational modifications (PTMs), disulfide bonds, variants, and structural information. It also includes intra- and inter-protein contacts, ligand interactions, and genetic variant data from ClinVar and AlphaMissense.
                        </p>
                        <p className="fs-1 text-justify"><b>How can I query the Atlantis database?</b><br/>
                         You can query Atlantis via gene symbols, UniProt accession numbers, or a combination of these plus specific residue positions.
                        </p>
                        <p className="fs-1 text-justify"><b>How do I retrieve bulk data from Atlantis?</b><br/>
                         Atlantis provides a GraphQL API for bulk retrieval of data. You can access it via the endpoint: https://api.atlantis.bioinfolab.sns.it/graphql . For more information, please refer to the <Link className="" to="/api/" target="_blank"><b> API</b></Link> page.
                        </p>
                        <p className="fs-1 text-justify"><b>What information can I retrieve for a specific residue?</b><br/>
                         You can access:
                         <ul class="mb-1 ml-10">
                            <li class="fs-1 text-justify">UniProt includes various features such as variants, molecule processing, membrane localizations, motifs, and more</li>
                            <li class="fs-1 text-justify">Protein architecture, including PFAM and InterPro domains and the aligned positions in the Hidden Markov Model (HMM)</li>
                            <li class="fs-1 text-justify">PTMs from PhosphoSitePlus</li>
                            <li class="fs-1 text-justify">IntAct binding regions</li>
                            <li class="fs-1 text-justify">Intra- and inter-protein contacts</li>
                            <li class="fs-1 text-justify">Ligand interactions from the PDB archive</li>
                            <li class="fs-1 text-justify">Variants from ClinVar</li>
                         </ul>
                        </p>
                        <p className="fs-1 text-justify"><b>Can I visualize protein structures in Atlantis?</b><br/>
                         Yes, you can view 3D structures using MolStar. Atlantis overlays residue-specific annotations and ligand interactions on these structures.
                        </p>
                        <p className="fs-1 text-justify"><b>How do I explore intra- and inter-protein contacts?</b><br/>
                         Atlantis features a dedicated section for intra- and inter-protein contacts obtained from both PDB database. Contacting positions can be visualized directly on the 3D structures.
                        </p>
                        <p className="fs-1 text-justify"><b>How does Atlantis handle genetic variant data?</b><br/>
                         Atlantis integrates genetic variant information from the ClinVar database, including pathogenicity scores and classifications from AlphaMissense. Variants are mapped to specific residues and can be visualized in the context of the protein’s 3D structure.
                        </p>
                         <p className="fs-1 text-justify"><b>What types of ligands are annotated in Atlantis?</b><br/>
                         Ligand annotations include functional categories such as cofactor-like, drug-like, and reactant-like ligands. Each ligand’s interacting residues, chains, and chemical identifiers (e.g., InChIKey, bmID) are provided.
                        </p>
                        <p className="fs-1 text-justify"><b>What structural information does Atlantis provide?</b><br/>
                         Atlantis includes pre-calculated intra- and inter-protein contacts from both experimental structures (PDB) and AlphaFold-predicted structures. It also provides AlphaFold’s pLDDT scores and secondary structure details.
                        </p>
              </div>
        </div>
    </div>
</section>

    <Footer/>
   	</>
   )
}

export default FaqPage