import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import 'primeicons/primeicons.css';

const VariantsTable = ({ VariantData, setSelectedVariants, setUniprotAccessionNumber, setPositionSummary }) => {
    const dt = useRef(null);

    const columns = [
        { field: 'anPosition', header: 'Position' },
        { field: 'locationG37', header: 'Location hg19' },
        { field: 'locationG38', header: 'Location hg38' },
        { field: 'ref', header: 'REF' },
        { field: 'alt', header: 'ALT' },
        { field: 'variant', header: 'Variant ID' },
        { field: 'consequence', header: 'Consequence' },
        { field: 'clinicalSignificance', header: 'Clinical significance' },
        { field: 'disease', header: 'Disease' },
        { field: 'proteinPosition', header: 'Protein position' },
        { field: 'aaChange', header: 'AA change' },
        { field: 'codon', header: 'Codon' },
        { field: 'alphamissenseScore', header: 'AlphaMissense score' },
        { field: 'alphamissenseClass', header: 'AlphaMissense class' }
    ];

    const multiSelectOptions = columns.map(col => ({ label: col.header, value: col.field }));
    const [visibleColumns, setVisibleColumns] = useState(columns);

    const onColumnToggle = (event) => {
        const selectedFieldNames = event.value;
        const updatedVisibleColumns = columns.filter(col => selectedFieldNames.includes(col.field));
        setVisibleColumns(updatedVisibleColumns);
    };

    const dynamicColumns = visibleColumns.map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} sortable />;
    });

    const [selectedRows, setSelectedRows] = useState([]);

    const handleRowSelect = (e) => {

        setSelectedRows(e.value);
        setPositionSummary("");

        // Extract proteinPosition from all selected rows
        const selectedProteinPositions = e.value.map(row => row.proteinPosition);
        let uniprotAccession = e.value.map(row => row.anPosition)

        if (uniprotAccession.length > 0){

           uniprotAccession = uniprotAccession[0].split("_")[0]
           setUniprotAccessionNumber(uniprotAccession);

        }

        setSelectedVariants(selectedProteinPositions); // Update the parent component with the protein positions
    };

    const onRowUnselect = (e) => {
        // Remove the unselected row from the selectedRows array
        const updatedSelectedRows = selectedRows.filter(row => row.proteinPosition !== e.data.proteinPosition);
        setSelectedRows(updatedSelectedRows);

        // Extract proteinPosition from updated selected rows
        const selectedProteinPositions = updatedSelectedRows.map(row => row.proteinPosition);
        setSelectedVariants(selectedProteinPositions); // Update the parent component with the new selection
    };

    const handleResetData = (event) => {
        setSelectedRows([]);
        setSelectedVariants([]);
        setPositionSummary("");
    };

    const exportCSV = (tableRef, selectionOnly) => {
        tableRef.current.exportCSV({ selectionOnly });
    };

    const exportExcel = async (dataToExport) => {
        const xlsx = await import('xlsx');
        const worksheet = xlsx.utils.json_to_sheet(dataToExport);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAsExcelFile(excelBuffer, 'data');
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const value = filters['global'] ? filters['global'].value : '';

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const header = (
        <div className="row align-items-center">
            <div className="col mb-2">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        value={value || ''}
                        onChange={(e) => onGlobalFilterChange(e)}
                        placeholder="Global Search"
                    />
                </span>
            </div>
            <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Button type="button" icon="pi pi-replay" text  onClick={handleResetData} tooltip="Unselect rows"/>
                <MultiSelect
                    value={visibleColumns.map(col => col.field)}
                    options={multiSelectOptions}
                    onChange={onColumnToggle}
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select Columns"
                    display="chip"
                    style={{ width: '200px', marginRight: '10px' }}
                />
                <Button
                    type="button"
                    icon="pi pi-file"
                    className="p-button-rounded p-mr-2"
                    onClick={() => exportCSV(dt, false)}
                    data-pr-tooltip="CSV"
                />
                <Button
                    type="button"
                    icon="pi pi-file-excel"
                    className="p-button-success p-button-rounded p-mr-2"
                    onClick={() => exportExcel(VariantData)}
                    data-pr-tooltip="XLS"
                />
            </div>
        </div>
    );

    return (
        <>
            <DataTable
                ref={dt}
                value={VariantData}
                paginator
                rows={5}
                removableSort
                header={header}
                selectionPageOnly
                selectionMode="multiple"  // Enables multi-row selection
                selection={selectedRows}   // Selected rows state
                onSelectionChange={handleRowSelect}  // Handles selection of multiple rows
                filters={filters}
                onFilter={(e) => setFilters(e.filters)}
                onRowUnselect={onRowUnselect}
                tableStyle={{ minWidth: '50rem' }}
            >
                {dynamicColumns}
            </DataTable>
        </>
    );
};

export default VariantsTable;
