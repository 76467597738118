import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {useNavigate} from "react-router-dom";
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import axios from 'axios';
import Swal from 'sweetalert2'
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatGroup,
} from '@chakra-ui/react';

const HomePage = () => {
  const [isDnaHovered, setIsDnaHovered] = useState(false);
  const [isProteinHovered, setIsProteinHovered] = useState(false);
  const [isResidueHovered, setIsResidueHovered] = useState(false);
  const [isResidueGeneHovered, setIsResidueGeneHovered] = useState(false);

  const handleDnaMouseEnter = () => setIsDnaHovered(true);
  const handleDnaMouseLeave = () => setIsDnaHovered(false);
  const handleProteinMouseEnter = () => setIsProteinHovered(true);
  const handleProteinMouseLeave = () => setIsProteinHovered(false);
  const handleResidueMouseEnter = () => setIsResidueHovered(true);
  const handleResidueMouseLeave = () => setIsResidueHovered(false);
  const handleResidueGeneMouseEnter = () => setIsResidueGeneHovered(true);
  const handleResidueGeneMouseLeave = () => setIsResidueGeneHovered(false);

  const navigate = useNavigate();
  const initialValues = {target: ""};
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const genesData = require('../../genes.json');
  const proteinsData = require('../../proteins.json');

const onChangeHandler = (e) => {const { name, value } = e.target;
setFormValues({ ...formValues, [name]: value.toUpperCase().trim() });  };

const onClickHandler = () => {
    setFormValues({ target: "NTS" });
};

const onClickHandlerProtein = () => {
    setFormValues({ target: "P30990" });
};

const onClickHandlerResidue = () => {
    setFormValues({ target: "P30990_151" });
};

const onClickHandlerResidueGene = () => {
    setFormValues({ target: "NTS_151" });
};

useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
        }
      }, [formErrors, formValues, isSubmit]);

const validateForm = (values) => {
const errors = {};
const regex = /^[^!@#$%^&*()+{}[\]:;<>,.?°~\\/]+$/;

    if(values.target.trim() === ""){
        errors.target = "Search term is required!";
    } else if (!regex.test(values.target)) {
      errors.target = 'This is not a valid format!';
    }
    return errors;
 };

function isValidUniProtAccession(input) {
  const found = proteinsData.some(protein => protein.PROTEIN_AN === input.trim());
  return found;
}

function isValidResidue(input) {
    const regex = /^[A-Z0-9]{6,10}(_[0-9]+)$/;
    const found = proteinsData.some(protein => protein.PROTEIN_AN === input.split("_")[0].trim());
    const test = regex.test(input.trim());
    let valid = false;

    if (test && found){
         valid = true;
    }
    return valid;
}

function isValidGeneSymbol(input) {
  const found = genesData.some(gene => gene.GENE_SYMBOL === input.trim());
  return found;
}

function isValidGeneResidue(input) {
    const regex = /^[A-Z0-9]{2,10}(_[0-9]+)$/;
    const found = genesData.some(gene => gene.GENE_SYMBOL === input.split("_")[0].trim());
    const test = regex.test(input.trim());
    let valid = false;

    if (test && found){
         valid = true;
    }
    return valid;
}

const handleFormSubmit = (e) => {
      e.preventDefault();
      const error = validateForm(formValues);
      setFormErrors(error);
       if(!error.target){
          setIsLoading(true);
          sendExploreData();
       }
       setIsSubmit(true);
    };

    // Function to send form data to the API
    async function sendExploreData() {
        try {

        const query = {
              query: `
                query getResiduesByGene {
                  residuesByGeneSymbol(geneSymbol: "${formValues.target.toUpperCase()}") {
                    anPosition
                    unRefProtRe
                    afRes
                    unAnnNatVariants
                    unMoleculeProcessing
                    unLocation
                    unMotives
                    unDomains
                    pfamDomainPosHmm
                    unLigandsBindingSite
                    unAnnPropActiveSite
                    phosPtms
                    unGlycosylations
                    unLipidations
                    unModifiedRes
                    unDisulfideBongBridges
                    unSecondaryStructure
                    afPredictedSecondaryStructure
                    afConfidenceValue
                    afMaxPredictedNumInteractionResidue
                    afResiduePredictedInteraction
                    unCrossLinks
                    inBindingRegion
                    interproDomain
                  }
                }
              `
            };

        const query_protein = {
              query: `
                query getResiduesByProtein {
                  residues(proteinAn: "${formValues.target.toUpperCase()}") {
                    anPosition
                    unRefProtRe
                    afRes
                    unAnnNatVariants
                    unMoleculeProcessing
                    unLocation
                    unMotives
                    unDomains
                    pfamDomainPosHmm
                    unLigandsBindingSite
                    unAnnPropActiveSite
                    phosPtms
                    unGlycosylations
                    unLipidations
                    unModifiedRes
                    unDisulfideBongBridges
                    unSecondaryStructure
                    afPredictedSecondaryStructure
                    afConfidenceValue
                    afMaxPredictedNumInteractionResidue
                    afResiduePredictedInteraction
                    unCrossLinks
                    inBindingRegion
                    interproDomain
                  }
                }
              `
            };

        const query_residue = {
              query: `
                query getResidueByPosition {
                  residue(anPosition: "${formValues.target.toUpperCase()}") {
                    anPosition
                    unRefProtRe
                    afRes
                    unAnnNatVariants
                    unMoleculeProcessing
                    unLocation
                    unMotives
                    unDomains
                    pfamDomainPosHmm
                    unLigandsBindingSite
                    unAnnPropActiveSite
                    phosPtms
                    unGlycosylations
                    unLipidations
                    unModifiedRes
                    unDisulfideBongBridges
                    unSecondaryStructure
                    afPredictedSecondaryStructure
                    afConfidenceValue
                    afMaxPredictedNumInteractionResidue
                    afResiduePredictedInteraction
                    unCrossLinks
                    inBindingRegion
                    interproDomain
                  }
                }
              `
            };

        const query_gene_residue = {
              query: `
                query getResidueByGeneAndPosition {
                  residueByGeneSymbolAndPosition(genePosition: "${formValues.target.toUpperCase()}") {
                    anPosition
                    unRefProtRe
                    afRes
                    unAnnNatVariants
                    unMoleculeProcessing
                    unLocation
                    unMotives
                    unDomains
                    pfamDomainPosHmm
                    unLigandsBindingSite
                    unAnnPropActiveSite
                    phosPtms
                    unGlycosylations
                    unLipidations
                    unModifiedRes
                    unDisulfideBongBridges
                    unSecondaryStructure
                    afPredictedSecondaryStructure
                    afConfidenceValue
                    afMaxPredictedNumInteractionResidue
                    afResiduePredictedInteraction
                    unCrossLinks
                    inBindingRegion
                    interproDomain
                  }
                }
              `
            };

        let navigateData = null;
        let type = "";
        const apiUrl = 'https://api.atlantis.bioinfolab.sns.it/graphql';

        if (isValidUniProtAccession(formValues.target) && !isValidResidue(formValues.target)){
           navigateData = await axios.post(apiUrl, query_protein);
           type = "protein";
        } else if (isValidResidue(formValues.target)){
          navigateData = await axios.post(apiUrl, query_residue);
          type = "residue";
        } else if (isValidGeneSymbol(formValues.target)) {
          navigateData = await axios.post(apiUrl, query);
          type = "gene";
        }else if (isValidGeneResidue(formValues.target)) {
          navigateData = await axios.post(apiUrl, query_gene_residue);
          type = "gene_residue";
        }

        if (!navigateData) {
                setIsLoading(false);
                Swal.fire({
                    icon: "info",
                    text: "No results found! Please change your search criteria and try again."
            });
            return;
        }else if (navigateData.data.errors){
               setIsLoading(false);
                Swal.fire({
                    icon: "error",
                    text: "Oops... \n An error has occurred!"
                });
                 return;
        }

      const handleNavigation = (hasData, path, data) => {
          setIsLoading(false);
          if (hasData) {

             // Append the form values as query parameters to the URL
              const url = new URL(window.location.href);
              url.searchParams.set('target', formValues.target);
              url.searchParams.set('type', type);

              navigate(path + url.search, { state: { 'target': formValues.target, 'type': type, 'data': data } });
          } else {
            Swal.fire({
              icon: "info",
              text: "No results found! Please change your search criteria and try again."
            });
          }
        }

        const checkDataAndNavigate = () => {
          // Check for no errors and data is present
          if (!navigateData.data.errors && navigateData.data.data) {
            switch (type) {
              case "gene":
                handleNavigation(navigateData.data.data.residuesByGeneSymbol.length > 0, '/explore/', navigateData.data.data);
                break;
              case "residue":
                handleNavigation(!!navigateData.data.data.residue, '/explore/', navigateData.data.data);
                break;
              case "protein":
                handleNavigation(navigateData.data.data.residues.length > 0, '/explore/', navigateData.data.data);
                break;
              case "gene_residue":
                handleNavigation(navigateData.data.data.residueByGeneSymbolAndPosition, '/explore/', navigateData.data.data);
                break;
              default:
                setIsLoading(false);
                Swal.fire({
                  icon: "info",
                  text: "Invalid search type! Please check your criteria and try again."
                });
            }
          } else {
            // Handle case where there are errors or no data
            setIsLoading(false);
            Swal.fire({
              icon: "info",
              text: "No results found! Please change your search criteria and try again."
            });
          }
        }

       checkDataAndNavigate();

     } catch (error) {

         setIsLoading(false);
         setError('An error occurred: ' + error.message);

          Swal.fire({
              icon: "error",
              text: error.message
          });
       }
    }

  return (
    <>
      <Helmet>
        <title>Atlantis | Home</title>
      </Helmet>
      <Header />
      <section className="home">
        <div className="container min-vh-100 d-flex justify-content-center align-items-center">
          <div className="row align-items-center col-12 col-lg-8">
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <img src="/assets/images/logo.png" alt="Database" className="img-fluid"/>
            </div>
            <div className="col-md-6 mb-5">
              <p>&nbsp;</p>
              <h1 className="display-6 fw-bold lh-sm">An integrative daTabase for human proteome structuraL And FuNcTIonal Sites</h1>
            </div>
            <div className="col-md-12 mb-5">
              <form onSubmit={handleFormSubmit} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <span className="p-input-icon-left" style={{ width: '100%' }}>
                    <InputText className="p-input text-lg" name="target" value={formValues.target} onChange={onChangeHandler} style={{ width: '100%' }} placeholder="Search by gene/protein/residue position" />
                    <Button
                      type="submit"
                      className="btn-form p-button-rounded p-button-secondary search-button"
                      style={{ position: 'absolute', right: 0 }}>
                      {loading ? (
                        <i className="pi pi-spin pi-spinner"></i>
                      ) : (
                        <i className="pi pi-search"></i>
                      )}
                    </Button>
                  </span>
                </div>
              </form>
              <div className="row d-flex flex-wrap justify-content-center align-items-center mt-3 mb-4">
              <div className="col">
                <div className="btn-group" role="group">
                  <Button className="btn-example mb-2" onMouseEnter={handleDnaMouseEnter} onMouseLeave={handleDnaMouseLeave} onClick={onClickHandler} tooltip="Search by gene symbol">
                    NTS&nbsp;
                    {isDnaHovered ? (
                      <img alt="logo" src="/assets/images/dna.gif" className="img-fluid btn-img" />
                    ) : (
                      <img alt="logo" src="/assets/images/dna.png" className="img-fluid btn-img" />
                    )}
                  </Button>
                </div>
              </div>
              <div className="col">
                <div className="btn-group" role="group">
                  <Button className="btn-example mb-2" onMouseEnter={handleProteinMouseEnter} onMouseLeave={handleProteinMouseLeave} onClick={onClickHandlerProtein} tooltip="Search by UniProt accession number">
                    P30990&nbsp;
                    {isProteinHovered ? (
                      <img alt="Protein GIF" src="/assets/images/protein.gif" className="img-fluid btn-img" />
                    ) : (
                      <img alt="Protein logo" src="/assets/images/protein.png" className="img-fluid btn-img" />
                    )}
                  </Button>
                </div>
              </div>
              <div className="col">
                <div className="btn-group" role="group">
                  <Button className="btn-example mb-2" onMouseEnter={handleResidueMouseEnter} onMouseLeave={handleResidueMouseLeave} onClick={onClickHandlerResidue} tooltip="Search by residue given a UniProt accession number and its protein sequence position">
                    P30990_151&nbsp;
                    {isResidueHovered ? (
                      <img alt="Residue GIF" src="/assets/images/chemist.gif" className="img-fluid btn-img" />
                    ) : (
                      <img alt="Residue logo" src="/assets/images/chemist.png" className="img-fluid btn-img" />
                    )}
                  </Button>
                </div>
              </div>
              <div className="col">
                <div className="btn-group" role="group">
                  <Button className="btn-example mb-2" onMouseEnter={handleResidueGeneMouseEnter} onMouseLeave={handleResidueGeneMouseLeave} onClick={onClickHandlerResidueGene} tooltip="Search by residue given a gene symbol and its protein sequence position">
                    NTS_151&nbsp;
                    {isResidueGeneHovered ? (
                      <img alt="Residue GIF" src="/assets/images/chemist.gif" className="img-fluid btn-img" />
                    ) : (
                      <img alt="Residue logo" src="/assets/images/chemist.png" className="img-fluid btn-img" />
                    )}
                  </Button>
                </div>
              </div>
            </div>

              <div className="row d-flex justify-content-center align-items-center">
              <StatGroup>
                <Stat>
                  <StatLabel>Residues</StatLabel>
                  <StatNumber className="display-5">11.378.289</StatNumber>
                  <StatHelpText>in 20.594 human proteins</StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>Intra-protein contacts</StatLabel>
                  <StatNumber className="display-5">888.080.37</StatNumber>
                  <StatHelpText>in 55.736 PDB structures</StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>Inter-protein contacts</StatLabel>
                  <StatNumber className="display-5">786.063.6</StatNumber>
                  <StatHelpText> in 11.320 PDB structures</StatHelpText>
                </Stat>
              </StatGroup>
            </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default HomePage;
